import React, { useContext } from "react";
import Navbar from "../navbar/navbar.js";
import { ThemeContext } from "../../context/ThemeContext";
import Footer from "../footer/footer.js";
import Particles from "react-tsparticles";

import WhiteCircle from "../../assets/img/svg/white-circle.svg";

import "./style.scss";

const Layout = ({ children }) => {
  const theme = useContext(ThemeContext);

  function closeMenu() {
    theme.setMenuOpen(false);
  }

  return (
    <>
      <Navbar />
      <div
        className="layout"
        role="button"
        tabIndex="0"
        onClick={closeMenu}
        onKeyPress={closeMenu}
      >
        <Particles
          style={{ position: "fixed", zIndex: -1 }}
          params={{
            particles: {
              line_linked: {
                enable: false,
              },
              move: {
                bounce: false,
              },
              number: {
                value: 5,
              },
              size: {
                value: theme.isMobile ? 50 : 150,
                random: true,
                anim: {
                  enable: false,
                  speed: 10,
                  size_min: 0.7,
                  sync: true,
                },
              },
              shape: {
                type: "images",
                image: [{ src: WhiteCircle, height: 200, width: 200 }],
              },
            },
          }}
        />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
